
.bit-footer {
    background: #101423;
    padding: 40px 0 30px 0;
    color: #999999;
    .big-row {
        justify-content: space-between;
    }
    .logo-img {
        width: 150px;
        margin-bottom: 56px;
        margin-left: 40px;
    }
    .footer-line {
        padding-left: 24px;
        >li {
            margin-bottom: 46px;
            p {
                margin-bottom: 30px
            }
            li {
                margin-bottom: 10px;
            }
        }
    }
    .footer-title {
        font-size: 17px;
        color: rgba(255,255,255,1);
    }
    .copyright {
        margin-top: 27px;
        font-size: 17px;
        text-align: center;
    }
    a {
        color: #999999;
        font-size: 14px;
    }
    a:hover {
        color: #9327A1;
    }
}
@media (min-width: 576px) {
    .bit-footer {
        padding-top: 56px;
        padding-left: 0;
        .logo-img {
            margin-left: 0;
        }
        .footer-line {
            padding-left: 0;
            >li {
                margin-bottom: 56px;
                p {
                    margin-bottom: 32px
                }
                li {
                    margin-bottom: 13px;
                }
            }
        }
        .copyright {
            margin-top: 58px;
            text-align: left;
        }
    }
}


.toast
  position fixed
  top 0
  bottom 0
  right 0
  left 0
  margin auto
  z-index 2147483647
  height 100%
  line-height 30px
  .toastMask
    padding 20px
    display inline-block
    color #fff
    border-radius 8px
    background-color rgba(0,0,0,0.7)
    box-shadow:6px 6px 10px 6px rgba(0,0,0,0.3)
    position absolute
    left 50%
    top 40%
    transform translateX(-50%)

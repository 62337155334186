
.title {
    font-size 52px;
    color rgba(38,38,38,1);
    line-height 73px;
    margin-top: 36px;
    margin-bottom 36px;
    font-weight 600
}
.des_tiny {
    text-align center
    margin 40px 0 43px
    img {
        width 100%
        max-height 100%
    }
    p{
        text-align left
        margin-bottom 76px;
    }
}

.contentList {
    margin-bottom 20px
    color #262626
    line-height 28px
    ._title {
        font-size 24px
        font-weight 600
    }
    ._content{
        font-size 16px
    }
    .sub_content {
        margin-top 20px
        font-size 16px
        .sub_title{
            font-weight 600
        }
        .sub_sub {
            >>>.right_before {
               position relative
               padding-left 16px
               margin-top 15px
               &:before {
                   content:url('../../assets/images/whatvpn_right.png');
                   position: absolute;
                   left -4px
                   top 4px
               }
            }
            >>>.none {
                margin-top 0px
            }
            
        }

    }
}
.btn-center {
    margin 30px 0px 99px
    button {
        margin 0 auto
        display block
    }
}


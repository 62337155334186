
.bit-button {
    padding: 24px 32px;
    background: linear-gradient(90deg,rgba(147,39,161,1) 0%,rgba(112,39,161,1) 100%);
    color: #fff;
    cursor: pointer;
    width: 100%;
    &:hover {
        background: linear-gradient(90deg,rgba(133,33,146,1) 0%,rgba(95,29,140,1) 100%);
    }
    &:active {
        background: rgba(113,39,161,1);
    }
    &.size-big {
        font-size: 24px;
        padding: 14px 5px;
        border-radius: 30px;
        line-height:33px;
    }
    &.size-small {
        padding: 9px 23px;
        border-radius: 20px;
        font-size: 16px;
    }
    &.size-medium {
        padding: 14px 21px;
        border-radius: 30px;
        font-size: 24px;
    }
}
@media (min-width: 576px) {
    .bit-button {
        width: auto;
        &.size-big {
            padding: 24px 40px;
            border-radius: 45px;
        }
        &.size-small {
            padding: 9px 36px;
            border-radius: 20px;
        }
    }
}


.bit-pay {
    color: rgba(38, 38, 38, 1);
    padding-left: 0;
    padding-right: 0;
    .promise {
        p {
            display: flex;
            font-size: 17px;
            margin-bottom: 16px;
        }
        img {
            width: 16px;
            margin-right: 8px;
            align-self: flex-start;
            margin-top: 2px;
        }
    }
    .agree-terms {
        margin-top: 24px;
        li {
            font-size: 17px;
            margin-left: 1em;
            margin-bottom: 16px;
        }
        li::before {
            content: '\2022';
            color: #262626;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
        a {
            color: #0C54F1;
        }
    }
    .left-pay {
        padding-left: 0;
        padding-right: 0;
    }
    .pay-name, .big-price {
        display: flex;
        justify-content: space-between;
    }
    .pay-name {
        font-size: 14px;
        margin-bottom: 8px;
    }
    .big-price {
        font-size: 24px;
        .unit {
            color: #DEDEDE;
            margin-right: 16px;
        }
    }
    .pay-price {
        border-bottom: 1px solid rgba(222, 222, 222, 1);
        padding-bottom: 16px;
        margin-bottom: 24px;
    }
}
@media (min-width: 576px) {
    .bit-pay {
        padding-left: 16px;
        padding-right: 16px;
        .pay-container {
            border: 1px solid rgba(222, 222, 222, 1);
            padding: 24px 24px 28px 24px;
            min-height: 450px;
        }
        .left-pay {
            padding-left: 32px;
            padding-right: 32px;
            flex-grow: 2;
        }

        .pay-name {
            font-size: 18px;
        }
        .big-price {
            font-size: 42px;
            .unit {
                color: #DEDEDE;
                margin-right: 16px;
            }
        }
        .pay-price {
            margin-bottom: 32px;
        }
        .agree-terms {
            li {
                margin-bottom: 8px;
            }
        }
        .promise {
            p {
                margin-bottom: 12px;
            }
        }
    }
}


.bit-header {
    height: 70px;
    width: 100%;
    .bit-header-container {
        
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        
    }
    .row {
        height: 70px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        max-width: 1140px;
    }
    .left, ul {
        display: flex;
        align-items: center;
    }
    .logo-img, li {
        margin-right: 10px;
    }
    li {
        padding: 0 16px;
    }

    .logo-img {
        width: 100px;
        cursor: pointer;
    }
    &.type-default {
        .bit-header-container {
            background: #fff;
        }
    }
    &.type-transparent {
        .bit-header-container {
            background: transparent;
        }
    }
    a {
        color: #262626;
        font-size: 14px;
        padding-bottom: 5px;
        display: inline-block;
    }
    a:hover {
        color: #9327A1;
    }
    .header-download {
        display: none;
    }
    &.isPayPage {
        .header-download {
            display: inline-block;
        }
    }
    .showDownload {
        display: inline-block;
    }
    .header-open-icon {
        display: inline-block;
        width: 24px;
        height: 17px;
        border-top: 3px solid #454545;
        border-bottom: 3px solid #454545;
        position: relative;
        &:after {
            content: ' ';
            position: absolute;
            height: 3px;
            display: inline-block;
            width: 100%;
            top: 4px;
            background: #454545;
        }
    }
    .header-close-icon {
        display: inline-block;
        width: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        color: #fff;
    }
    .hide-menu {
        display: none;
    }
    &.show-menu {
        background: #101423;
        position: fixed;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        .container {
            background: #101423;
        }
        .header-content {
            position: absolute;
            width: 100%;
            top: 210px;
            left: 0;
            ul {
                display: block;
            }
            li {
                margin-bottom: 40px;
                text-align: center;
            }
            a {
                color: #fff;
                font-size: 32px;
            }
        }
    }
}
@media (min-width: 576px) {
    .bit-header {
        // width: 100%;
        // .container {
        //     // position: static;
        //     width: 100%;
        // }
        .logo-img, li {
            margin-right: 32px;
        }
        .logo-img {
            margin-right: 84px;
        }
        .header-close-icon, .header-open-icon {
            display: none;
        }
        .header-download {
            display: inline-block;
        }
        .hide-menu {
            display: block;
        }
        .active {
            border-bottom: 2px solid rgba(147,39,161,1);
        }
    }
}
.isPayPage {
}


.faq {
    h1 {
        font-size: 24px;
        color: rgba(38,38,38,1);
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(222,222,222,1);
        margin-bottom: 24px;
        margin-top: 32px;
    }
    .title {
        font-size: 24px;
        font-weight: 600;
        color: rgba(57,66,102,1);
        line-height: 33px;
        padding-bottom: 20px;
    }
    .content {
        font-size: 18px;
        font-weight: 400;
        color: rgba(115,122,148,1);
        line-height: 33px;
        padding-bottom: 10px;
        transition: all 3s;
        .intro {
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.5px solid #ccc;
        }
        span {
            color: rgba(57,66,102,1);
            font-weight: 600;
            line-height: 33px;
            width: 96%;
        }
      .contentdetail {
            padding-top: 4px;
            word-break: break-word;
        }
    }
    .content:nth-last-of-type(1) {
        padding-bottom: 30px;
    }
    .title {
        font-size:18px;
        font-family:Helvetica;
        color:rgba(12,84,241,1);
        line-height:22px;
        &::before {
            content: "\2022";
            color: rgba(153,153,153,1);
            font-weight: bold;
            display: inline-block;
            font-size: 24px;
            height: 19px;
            line-height: 19px;
            width: 16px;
            margin-left: -16px;
            vertical-align: middle;
        }
    }
    .faqs-list {
        margin-bottom: 80px;
    }
}
@media (min-width: 576px) {
    .faq {
        h1 {
            margin-top: 0px;
            font-size: 42px;
            margin-bottom: 40px;
        }
        .faqs-item {
            margin-bottom: 24px;
        }
    }
}


.bit-download {
    display: flex;
    justify-content: space-around;
    background-repeat: no-repeat;
    align-items: flex-start;
    height: 140px;
    button {
        display: none;
        margin-top: 10px;
        color: #fff;
        padding: 6px 10px;
        background: rgba(171,86,181,1);
        border-radius: 4px;
        font-size:12px;
        font-family:PingFangSC-Medium;
        font-weight:500;
        color:rgba(255,255,255,1);
        line-height:17px;
        cursor: pointer;
    }
    .img-wrap {
        height: 50px;
    }
    .over_img {
        display: none;
    }
    img {
        width: 30px;
    }
    li {
        height: 130px;
        width: 60px;
        padding: 10px 0;
        font-size: 14px;
    }
    .hover {
        background:rgba(255,255,255,1);
        box-shadow:0px 1px 4px 0px rgba(0,0,0,0.3);
        width: 100px;
        button {
            display: inline-block;
        }
        .over_img {
            display: inline-block;
        }
        .out_img {
            display: none;
        }
    }
}
@media (min-width: 576px) {
    .bit-download {
        justify-content: center;
        height: 200px;
        li {
            width: 150px;
            font-size: 17px;
            height: 180px;
            padding: 20px 0;
        }
        button {
            margin-top: 20px;
            padding: 6px 20px;
        }
        .hover {
            width: 150px;
        }
        img {
            width: 45px;
        }
        .img-wrap {
            height: 60px;
        }
    }
}

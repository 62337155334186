
.pricing {
    .pricing-top {
        color: #fff;
        background: linear-gradient(180deg,rgba(60,26,65,1) 0%,rgba(44,18,71,1) 100%);
        padding: 40px 0 24px 0;
        text-align: center;
        h1 {
            font-size: 32px;
            margin-bottom: 16px;
        }
        .header-des-item {
            padding: 0 24px;
            margin-bottom: 8px;
            text-align: left;
            font-size: 14px;
            img {
                margin-right: 8px;
                width: 12px;
            }
            .des-one-item {
                display: flex;
                margin-bottom: 8px;
            }
        }
    }
    .choose-a-plan {
        padding: 24px 0 8px;
        background:rgba(245,245,245,1);
        .planlist {
            margin-top: 16px;
            .col-md-6 {
                display: flex;
                justify-content: center;
            }
            .pc-planitem {
                display: none;
            }
            .mobile-planitem {
                display: flex;
            }
        }
        .planitem:hover {
            border: 2px solid #9327A1;
        }
        .planitem {
            flex-grow: 1;
            max-width: 440px;
            color: #262626;
            border-radius: 8px;
            border: 2px solid rgba(222,222,222,1);
            text-align: center;
            padding: 20px 0 26px;
            position: relative;
            margin-bottom: 16px;
            cursor: pointer;
            background: #fff;
            .m-item-line1, .m-item-line2 {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .m-item-line1 {
                font-size: 18px;
                color: #262626
                .m-price {
                    font-size: 28px;
                }
                .l-year {
                    display: flex;
                    align-items: center;
                }
                .l-year-right {
                    margin-left: 8px;
                }
            }
            .m-item-line2 {
                font-size: 12px;
                color: #666;
                .m-per-month {
                    color: #999;
                }
            }
            .m-top-right-save {
                border-radius:0px 0px 30px 30px;
                display: inline-block;
                padding: 2px 22px;
                position: absolute;
                left: 50%;
                top: -2px;
                transform: translateX(-50%);
                background-image: url(../../assets/images/bug_bg.png);
                background-size: contain;
                .m-save-save {
                    font-size:16px;
                    color: #fff;
                }
            }
            .top-title {
                font-size: 24px;
            }
            .line {
                border-bottom: 1px solid #DEDEDE;
                margin: 4px auto 40px auto;
                width: 60%;
            }
            .price {
                font-size: 74px;
                line-height: 1;
                span {
                    font-size: 42px;
                    margin-right: 5px;
                }
            }
            .per-month {
                font-size: 14px;
                color: rgba(51,51,51,1);
                margin-bottom: 20px;
            }
            .des {
                font-size: 14px;
                color: rgba(153,153,153,1);
                margin-bottom: 12px;
            }
            &.active {
                border: 4px solid #9327A1;
                .line {
                    border-bottom-color: #9327A1;
                }
            }
            .top-right-save {
                position: absolute;
                right: 0;
                top: 0;
                background: #9327A1;
                color: #fff;
                width: 100px;
                height: 100px;
                background: linear-gradient(to top right, #fff 0%, #fff 50%, #9327A1 50%, #9327A1 100%);
                text-align: right;
                border-top-right-radius: 4px;
                .save-save {
                    font-size: 20px;
                    padding-right: 12px;
                }
                .save-percent {
                    font-size: 24px;
                }
                span {
                    font-size: 14px;
                }
            }
        }
        .mobile-planitem {
            .planitem {
                padding: 20px 12px 16px 12px;
            }
        }
    }
    .select-payment {
        padding: 24px 0 0;
        .pay-gallery {
            margin-top: 24px;
        }
        .down-icon {
            display: inline-block;
            border-top: 3px solid #999;
            border-left: 3px solid #999;
            width: 15px;
            height: 15px;
            transform: rotate(-135deg);
            position: relative;
            top: -3px;
        }
        .bit-radio {
            display: none;
        }
        .m-pay-item-title {
            display: flex;
            justify-content: space-between;
            padding: 16px;
            height: 66px;
        }
        .m-hide-cards {
            border-top: 1px solid rgba(211,211,211,1);
            padding: 25px 16px;
        }
        .m-hide-cards-line {
            margin: 0 16px 27px 16px;
            border-top: 1px solid rgba(211,211,211,1);
        }
        .pay-item {
            border: 1px solid rgba(211,211,211,1);
            margin-bottom: 16px;
            &.active {
                background: #fff;
                .down-icon {
                    transform: rotate(45deg);
                    top: 4px;
                }
            }
            span {
                position relative
                color: rgba(38,38,38,1);
                font-size: 18px;

                .tips{
                    position absolute
                    top 2px
                    right -20px
                    display: block;
                    width:16px;
                    height:16px;
                    background: url(../../assets/images/questionmark.jpg) no-repeat center;
                    background-size: 100% 100%
                }
            }
            .title-line {
                display: flex;
                align-items: center;
                .title-text {
                    margin-left: 16px;
                }
            }
        }
        .images {
            margin-top: 0;
            img {
                width: 50px;
                margin-right: 8px;
                vertical-align: top;
            }
        }
    }
    .pricing-title {
        display: flex;
        align-items: flex-start;
        color:rgba(51,51,51,1);
        span {
            font-size:12px;
            margin-right: 8px;
            padding: 4px 8px;
            background: #DEDEDE;
            border-radius: 23px;
            flex-shrink: 0;
        }
        .title {
            font-size: 18px;
        }
    }
}
@media (min-width: 576px) {
    .pricing {
        .pricing-top {
            padding: 40px 0;
            h1 {
                font-size: 64px;
            }
        }
        .header-des-item {
            padding: 0 50px;
            display: flex;
            justify-content: space-around;
            margin-bottom: 8px;
            img {
                width: auto;
            }
        }
        .choose-a-plan {
            background:rgba(245,245,245,1);
            padding: 32px 0 60px;
            .planlist {
                margin-top: 44px;
                .pc-planitem {
                    display: flex;
                }
                .mobile-planitem {
                    display: none;
                }
            }
        }
        .pricing-title {
            span {
                font-size:20px;
                margin-right: 16px;
                padding: 4px 16px;
                background: #DEDEDE;
                margin-top: 5px;
            }
            .title {
                font-size: 32px;
            }
        }
        .select-payment {
            padding: 32px 0 40px;
            .pay-gallery {
                margin-top: 40px;
                margin-bottom: 10px;
            }
            .m-pay-item-title {
                display: block;
                padding: 0;
                height: auto;
            }
            .pay-item {
                background: rgba(245,245,245,1);
                padding: 16px 13px;
                margin-bottom: 30px;
                cursor: pointer;
                span {
                    font-size: 24px;

                    .tips{
                        position absolute
                        top 5px
                        right -30px
                        display: block;
                        width:20px;
                        height:20px;
                        background: url(../../assets/images/questionmark.jpg) no-repeat center;
                        background-size: 100% 100%
                    }
                }
                .title-line {
                    .title-text {
                        margin-left: 24px;
                    }
                }
            }
            .images {
                margin-top: 17px;
            }
            .down-icon {
                display: none;
            }
            .bit-radio {
                display: inline-block;
            }
        }
    }
}


.bit-radio {
    border-radius: 50%;
    display: inline-block;
    border: 1px solid rgba(211,211,211,1);
    position: relative;
    transition: all 0.3s;
    &.size-big {
        width: 42px;
        height: 42px;
        border: 2px solid rgba(211,211,211,1);
    }
    &.size-small {
        width: 32px;
        height: 32px;
    }
    &.size-m-small {
        width: 20px;
        height: 20px;
    }
    &.selected {
        &.type-purple {
            background: #9327A1;
            border-color: #9327A1;
        }
        &.type-blue {
            background: #118BF9;
            border-color: #118BF9;
        }
        &::before {
            position: absolute;
            content: " ";
            display: inline-block;
            width: 7px;
            height: 15px;
            border-top: 3px solid #fff;
            border-left: 3px solid #fff;
            transform: rotate(-135deg);
            left: 11px;
            top: 5px;
        }
        &.size-big {
            &::before {
                width: 10px;
                height: 20px;
                border-top: 3px solid #fff;
                border-left: 3px solid #fff;
                left: 15px;
                top: 6px;
            }
        }
        &.size-small {
            &::before {
                width: 8px;
                height: 15px;
                border-top: 2px solid #fff;
                border-left: 2px solid #fff;
                left: 11px;
                top: 6px;
            }
        }
        &.size-m-small {
            &::before {
                width: 6px;
                height: 10px;
                border-top: 2px solid #fff;
                border-left: 2px solid #fff;
                left: 6px;
                top: 3px;
            }
        }
    }
}

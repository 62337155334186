
.title {
    font-size 52px;
    color rgba(38,38,38,1);
    line-height 73px;
    margin-top: 36px;
    margin-bottom 36px;
    font-weight 600
}
.contentList {
    margin-bottom 20px
    color #262626
    line-height 28px
    ._title {
        font-size 24px
        font-weight 600
    }
    ._content{
        font-size 16px
    }
    .sub_content {
        margin-top 20px
        .sub_title{
            font-weight 600
        }
        .sub_sub {
           // line-height 25px
        }

    }
}
.btn-center {
    margin 30px 0px 99px
    button {
        margin 0 auto
        display block
    }
}


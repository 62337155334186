
.right-solution {
    display: none;
    border: 1px solid rgba(222, 222, 222, 1);
    padding: 24px 24px 28px 24px;
    h5 {
        font-size: 16px;
        margin-bottom: 16px;
    }
    p {
        font-size: 12px;
        margin-bottom: 8px;
        display: flex;
    }
    img {
        width: 16px;
        margin-right: 8px;
        align-self: flex-start;
    }
}
@media (min-width: 576px) {
    .right-solution {
        display: block;
    }
}

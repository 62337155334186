
.dig-pur {
    .what-next {
        margin-bottom: 52px;
        h2 {
            font-size: 32px;
            color: rgba(51,51,51,1);
            margin-top: 40px;
            margin-bottom: 16px;
        }
        li {
            font-size: 17px;
            margin-left: 1em;
            margin-bottom: 8px;
            color:rgba(102,102,102,1);
            word-wrap: break-word;
        }
        li::before {
            content: "\2022";
            color: rgba(102,102,102,1);
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
    .input-wrap {
        position: relative;
        margin-bottom: 24px;
        input {
            height: 64px;
            font-size: 12px;
            padding-top: 24px;
            padding-bottom: 24px;
        }
        .icon-copy {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            width: 22px;
            height: 22px;
            cursor: pointer;
        }
    }
    .order-details {
        color:rgba(38,38,38,1);
        font-size:14px;
        padding-top: 32px;
        .border-line {
            border-bottom: 1px solid rgba(222,222,222,1);
            margin-top: 40px;
        }
        h2 {
            font-size: 24px;
            color: rgba(51,51,51,1);
            margin-bottom: 25px;
        }
        .detail {
            margin-bottom: 32px;
        }
        .detail-line {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
        }
        .detail-time {
            color: #E92B2B;
        }
        .detail-time {
            color: #E92B2B;
        }
        button {
            width: 100%;
        }
    }
    .total-order-amount {
        background: rgba(245,245,245,1);
        padding: 32px 0 24px 0;
        .total-title {
            margin-bottom: 16px;
            color: rgba(0,0,0,1);
            font-size: 18px;
            text-align: center;
        }
        .tips {
            display: flex;
            margin-top: 16px;
            p {
                color: rgba(248,126,56,1);
                font-size: 14px;
            }
            img {
                width: 12px;
                height: 12px;
                display: inline-block;
                line-height: 12px;
                font-size: 12px;
                align-self: flex-start;
                margin-top: 4px;
                margin-right: 8px;
                flex-shrink: 0;
            }
        }
        .m-tips {
            display: flex;
        }
        .pc-tips {
            display: none;
        }
        .erweima {
            padding: 30px 10px 16px 10px;
            background: #fff;
            width: 100%;
            img {
                margin: auto;
            }
            p {
                font-size: 18px;
                color:rgba(51,51,51,1);
                margin-top: 16px;
                text-align: center;
            }
            #qrcode{
                display: flex;
                justify-content: center
            }
        }
    }
    .loading {
        position:fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin:auto;
        z-index: 2147483647;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background:  #fff;
    }

}
@media (min-width: 576px) {
    .dig-pur {
        .what-next {
            margin-bottom: 100px;
        }
        .total-order-amount {
            padding: 64px 0;
            .total-title {
                margin-bottom: 44px;
                font-size: 36px;
                text-align: left;
            }
            .erweima {
                width: 300px;
            }
            .m-tips {
                display: none;
            }
            .pc-tips {
                display: flex;
            }
        }
        .input-wrap {
            margin-bottom: 0;
            input {
                height: 64px;
                font-size: 18px;
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
        .order-details {
            font-size:24px;
            padding-top: 24px;
            h2 {
                font-size: 32px;
                margin-bottom: 40px;
            }
            .detail {
                margin-bottom: 64px;
            }
            .detail-line {
                margin-bottom: 16px;
            }
            button {
                max-width: 600px;
            }
        }
    }
}


.bit-popup {
    position :fixed;
    top :0;
    bottom :0;
    right :0;
    left :0;
    margin :auto;
    z-index :10001;
    height:100%;
    width:100%;
    overflow: hidden;
    background: rgba(128, 128, 128, 0.6);
    .content{
        width:30%;
        padding: 16px 26px 56px 26px;
        position:absolute;
        z-index :2147483648;
        left :50%;
        top :50%;
        transform:translate(-50%,-50%);
        background :rgba(255,255,255,1) !important;
        @media screen and (max-width: 1700px) {
            width: 40%;
        }
        @media screen and (max-width: 1200px) {
            width: 60%;
        }
        @media screen and (max-width: 970px) {
            width: 70%;
        }
        @media screen and (max-width: 768px) {
            width: 90%;
        }
        @media screen and (max-width: 468px) {
            width: 95%;
        }
        .close{
            position:absolute;
            right:6px;
            top:6px;
            width:24px;
            height:24px;
            font-size:24px;
            color:#999999;
            cursor: pointer;
        }
        .title{
            font-size:24px;
            font-family:PingFangSC-Regular;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:35px;
            margin-top: 60px;
            margin-bottom:30px
        }
        .inputsty{

        }
        .tip{
            color :red;
            font-size :18px;
            height:24px;
        }
        .tip2{
            height:24px;
            color :#fff;
        }
        .btn1{
            margin-top:56px;
            display :flex;
            justify-content :center;
        }
    }
}

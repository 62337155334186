
.bit-about {
    h1 {
        font-size: 24px;
        color: rgba(38,38,38,1);
        margin: 32px 0 8px 0;
    }
    .time {
        margin-bottom: 32px;
    }
    .about-content {
        margin-bottom: 80px;
        p {
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 25px;
            color: #262626;
            font-family: Regular;
            font-weight: 500;
        }
        span {
            font-size: 24px;
            line-height: 25px;
            margin-top: 20px;
            margin-bottom: 10px;
            font-family: PingFangSC-Semibold;
            font-weight: 600;
            color: #394266;
            display: inline-block;
        }
    }
}
@media (min-width: 576px) {
    .faq {
        h1 {
            font-size: 42px;
            margin: 30px 0 30px 0;
        }
    }
}

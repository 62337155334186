
.products {
    .des {
        margin-top: 24px;
    }
    .products-header {
        background: #101423;
        .product-top-tab {
            justify-content: space-around;
            padding: 16px 0 40px
            span {
                cursor: pointer;
                display: inline-block;
                background-position: center 20px;
                background-repeat: no-repeat;
                width: 60px;
                height: 60px;
                margin: 0;
                background-size: 40%;
                &.active {
                    border-bottom: 2px solid rgba(147,39,161,1);
                }
            }
            .icon-ios {
                background-image: url('../../assets/images/ios-normal@2x.png');
                &:hover {
                    background-image: url('../../assets/images/ios-hover@2x.png');
                }
                &.active {
                    background-image: url('../../assets/images/ios-active@2x.png');
                }
            }
            .icon-android {
                background-image: url('../../assets/images/android-normal@2x.png');
                &:hover {
                    background-image: url('../../assets/images/android-hover@2x.png');
                }
                &.active {
                    background-image: url('../../assets/images/android-active@2x.png');
                }
            }
            .icon-mac {
                background-image: url('../../assets/images/macos-normal@2x.png');
                &:hover {
                    background-image: url('../../assets/images/macos-hover@2x.png');
                }
                &.active {
                    background-image: url('../../assets/images/macos-active@2x.png');
                }
            }
            .icon-windows {
                background-image: url('../../assets/images/windows-normal@2x.png');
                &:hover {
                    background-image: url('../../assets/images/windows-hover@2x.png');
                }
                &.active {
                    background-image: url('../../assets/images/windows-active@2x.png');
                }
            }
        }
        .header-content {
            color: #fff;
            background-repeat: no-repeat;
            background-position: bottom left;
            padding-bottom: 290px;
            background-size: 100%;
            &.vpn-for-ios {
                background-image: url('../../assets/images/product_header_bg_m.png');
            }
            &.vpn-for-android {
                background-image: url('../../assets/images/product_header_bg_android_m.png');
            }
            &.vpn-for-windows {
                background-image: url('../../assets/images/product_header_bg_windows_m.png');
            }
            &.vpn-for-mac {
                background-image: url('../../assets/images/product_header_bg_macos_m.png');
            }
            h1 {
                font-size: 32px;
                text-align: center;
            }
            p {
                font-size: 16px;
                margin-bottom: 24px;
                text-align: center;
            }
            img {
                width: 16px;
                margin-right: 16px;
                margin-top: 2px;
            }
            li {
                margin-bottom: 8px;
                font-size:14px;
                display: flex;
            }
            ul {
                margin-bottom: 32px;
            }
            .android-btn,.android-btn1 {
                display: flex;
                justify-content: center;
                button {
                    max-width: 300px;
                    height: 60px;
                    font-size: 20px;
                }
                .vpn-for-android {
                    width: 208px;
                    @media screen and (max-width:576px) {
                        width: 50%;
                    }
                }
                .google_play {
                    flex-shrink: 0;
                    width: 208px;
                    height: 75px;
                    vertical-align: top;
                    cursor: pointer;
                    margin-bottom: 10px;
                    @media screen and (max-width:576px) {
                        width: 50%;
                        height: 60px;
                    }
                }
            }
            .android-btn1 {
                 justify-content: space-between;
            }
        }
    }

    .why-vpn-network {
        background: #D8D8D8;
        text-align: center;
        color: rgba(38,38,38,1);
        padding: 32px 0 80px;
        margin-top: 0;
        background-image: url('../../assets/images/product_bg_mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        h2 {
            font-size: 32px;
            margin-bottom: 8px;
        }
        p {
            font-size: 16px;
            margin-bottom: 24px;
            a {
                color: #3B74E8;
            }
        }
    }
}
@media (min-width: 576px) {
    .products {
        .products-header {
            .product-top-tab {
                padding: 40px 0 50px;
                span {
                    background-position: center 9px;
                    background-size: 60%;
                }
            }
            .header-content {
                padding-bottom: 90px;
                &.vpn-for-ios {
                    background-image: url('../../assets/images/product_header_bg.png');
                }
                &.vpn-for-android {
                    background-image: url('../../assets/images/product_header_bg_android.png');
                }
                &.vpn-for-windows {
                    background-image: url('../../assets/images/product_header_bg_windows.png');
                }
                &.vpn-for-mac {
                    background-image: url('../../assets/images/product_header_bg_macos.png');
                }
                h1 {
                    font-size: 52px;
                    text-align: left;
                }
                p {
                    font-size: 24px;
                    text-align: left;
                }
                li {
                    font-size:18px;
                }
                ul {
                    margin-bottom: 70px;
                }
                .google_play {
                    width: 260px;
                    height: 75px;
                }
                .android-btn,.android-btn1 {
                    display: flex;
                    justify-content: flex-start;
                    button {
                        max-width: 600px;
                        height: 75px;
                        padding: 24px 30px;
                        font-size: 24px;
                    }
                    .vpn-for-android {
                        width: auto;
                    }
                }
            }
        }
        .why-vpn-network {
            background-image: url('../../assets/images/product_bg.png');
            padding: 56px 0 192px;
            margin-top: 64px;
            h2 {
                font-size: 56px;
                margin-bottom: 16px;
            }
            p {
                font-size: 24px;
                margin-bottom: 64px;
            }
        }
    }
}


.bit-paymentwall {
  .images {
      margin-top: 17px;
      img {
          width: 50px;
          margin-right: 8px;
      }
  }
}

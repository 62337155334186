
.pur-success {
    color: #262626;
    .thank-you {
        margin-top: 32px;
        margin-bottom: 32px;
        text-align: center;
        h1 {
            font-size: 46px;
            color: #9327A1;
            margin-bottom: 8px;
        }
        p {
            color: rgba(38,38,38,1);
            font-size: 18px;
        }
    }
    .get-bitvpn {
        margin-top: 24px;
        text-align: center;
        h2 {
            font-size: 24px;
            color: rgba(38,38,38,1);
            margin-bottom: 8px;
        }
    }
    .order-details {
        color:rgba(38,38,38,1);
        font-size:14px;
        padding-top: 32px;
        .border-line {
            border-bottom: 1px solid rgba(222,222,222,1);
            margin-top: 40px;
        }
        h2 {
            font-size: 24px;
            color: rgba(51,51,51,1);
            margin-bottom: 33px;
        }
    }
    .detail {
        margin-bottom: 32px;
    }
    .detail-line {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        span {
            color:rgba(38,38,38,1);
        }
        span:first-child {
            font-family:PingFangSC-Regular;
            font-weight:400;
            flex-shrink: 0;
            margin-right: 10px;
        }
        span:last-child {
            font-family:PingFangSC-Medium;
            font-weight:500;
            text-align: right;
        }
    }
    .save-your-receipt {
        background: rgba(245,245,245,1);
        padding: 32px 0 24px;
        .detail {
            margin-top: 33px;
        }
        .receipts-p {
            font-size: 16px;
        }
        .detail-line {
            span:last-child {
                color: #9327A1;
            }
        }
        h2 {
            margin-bottom: 8px;
            color: rgba(0,0,0,1);
            font-size: 24px;
        }
        .email-input-wrap {
            display: block;
            .btn-wrap1 {
                width: 100%;
                margin-top: 16px;
            }
        }
        .email-label {
            margin-top: 24px;
            margin-bottom: 9px;
            font-size: 18px;
            color: rgba(51,51,51,1);
            flex-shrink: 0;
            margin-right: 10px;
        }
        .green-small {
            width: 100%;
            font-size:24px;
            padding: 14px;
            min-width: 0;
        }
        .no-line {
            border-bottom: 1px solid rgba(222,222,222,1);;
            margin: 32px 0 24px;
        }
        .erweima {
            padding: 24px;
            background: #fff;
            img {
                width: 252px;
            }
            p {
                font-size: 18px;
                color:rgba(51,51,51,1);
                margin-top: 24px;
                text-align: center;
            }
        }
    }
    .tips {
        display: flex;
        margin-top: 16px;
        p {
            color: rgba(38,38,38,1);
            font-size: 14px;
        }
        img {
            width: 12px;
            height: 12px;
            display: inline-block;
            line-height: 12px;
            font-size: 12px;
            align-self: flex-start;
            margin-top: 3px;
            margin-right: 8px;
            flex-shrink: 0;
        }
    }
}
@media (min-width: 576px) {
    .pur-success {
        .thank-you {
            margin-bottom: 40px;
            text-align: center;
            h1 {
                font-size: 72px;
                margin-bottom: 0;
            }
            p {
                font-size: 24px;
            }
        }
        .get-bitvpn {
            margin-top: 80px;
            h2 {
                font-size: 56px;
                margin-bottom: 40px;
            }
        }
        .order-details {
            font-size:24px;
            padding-top: 24px;
            h2 {
                font-size: 32px;
                margin-bottom: 40px;
            }
            .detail {
                margin-bottom: 64px;
            }
        }
        .save-your-receipt {
            padding: 32px 0 64px;
            .email-input-wrap {
                flex-wrap: nowrap;
                align-items: center;
                display: flex;
                .btn-wrap1 {
                    width: 150px;
                    margin-left: 10px;
                    margin-top: 0;
                }
            }
            .detail {
                margin-top: 56px;
            }
            h2 {
                margin-bottom: 28px;
                font-size: 36px;
            }
            .receipts-p {
                font-size: 17px;
            }
            .no-line {
                margin: 56px 0 24px;
            }
            .email-label {
                font-size: 24px;
                margin-top: 0;
                margin-bottom: 0;
            }
            .green-small {
                padding: 4px;
            }
        }
        .detail-line {
            margin-bottom: 16px;
        }
    }
}

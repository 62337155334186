
.products-item {
    display: flex;
    color: rgba(38,38,38,1);
    margin-bottom: 32px;
    &.revers {
    }
    .content-wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .img-wrap {
        text-align: center;
    }
    img {
        width: 86px;
        margin-right: 16px;
    }
    h2 {
        color:rgba(38,38,38,1);
        font-size:18px;
        margin-bottom: 8px;
    }
    li {
        font-size: 14px;
        margin-left: 16px;
        margin-bottom: 8px;
    }
    li::before {
        content: "\2022";
        color: rgba(153,153,153,1);
        font-weight: bold;
        display: inline-block;
        font-size: 24px;
        height: 19px;
        line-height: 19px;
        width: 16px;
        margin-left: -16px;
        vertical-align: middle;
    }
}
@media (min-width: 576px) {
    .products-item {
        &.revers {
            flex-direction: row-reverse;
        }
        img {
            width: 298px;
            margin-right: 0;
        }
        .img-wrap, .content-wrap {
            width: 50%;
        }
        h2 {
            font-size:32px;
            margin-bottom: 24px;
        }
        li {
            font-size: 17px;
        }
    }
}

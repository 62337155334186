
.bit-paypal {
    #paypal_btn{
        position: relative;
        .maskshow{
             position: absolute;
             z-index: 10000;
             height: 80px;
             width: 100%;
             top: 0;
             cursor: pointer;
        }
    }
}

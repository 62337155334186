
.home {
    background-image: url('../../assets/images/home_mobile_bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    .bg-container {
        background-image: url('../../assets/images/home_computer2_m.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100%;
    }
    .bit-video {
        background-repeat: no-repeat;
        padding: 0 27px;
        .youtube-container-img {
            width: 100%;
            max-width: 378px;
            cursor: pointer;
        }
        h3 {
            color: rgba(38,38,38,1);
            font-size: 24px;
            margin-bottom: 10px;
        }
        .h3-pc {
            display: none;
        }
        .h3-mobile {
            text-align: center;
        }
        p {
            display: none;
        }
    }
    .vpn-with-aa {
        color: #fff;
        padding-top: 100px;
        padding-bottom: 177px;
        h2 {
            font-size: 24px;
            margin-bottom: 15px;
            text-align: center;
        }
        p {
            font-size: 16px;
            text-align: center;
        }
    }
    .why-choose-bitvpn {
        margin-bottom: 10px;
        margin-top: 24px;
        text-align: center;
        font-size: 42px;
        h2 {
            font-size:24px;
        }
        ul {
            text-align: center;
            margin-top: 24px;
        }
        li {
            padding: 0 30px;
            margin-bottom: 41px;
            display: flex;
            text-align: left;
        }
        img {
            width: 78px;
            margin-right: 20px;
        }
        h4 {
            color: #262626;
            font-size: 18px;
            margin-bottom: 2px;
        }
        p {
            color: rgba(38,38,38,1);
            font-size: 14px;
        }
    }
    .h-top {
        position: relative;
        margin-top: 40px;
        img {
            display: none;
        }
        h1 {
            font-size: 42px;
            color: #262626;
            line-height:48px;
        }
        p {
            font-size: 16px;
            color: rgba(38,38,38,1);
            margin-bottom: 32px;
        }
        .get-now-wrap {
            padding: 0 11px;
        }
    }
    .banner-img {
        width: 100%;
        overflow: hidden;
        margin-top: 50px;
        background:linear-gradient(180deg,rgba(255,255,255,1) 0%,rgba(207,207,207,1) 100%);
        img {
            width: 110%;
            vertical-align: top;
        }
    }
    .posu {
        text-align: center;
        color: rgba(38,38,38,1);
        margin-top: 40px;
        .posu-item {
            text-align: left;
            display: flex;
            margin-bottom: 48px;
        }
        img {
            width: 64px;
            margin-right: 27px;
        }
        h4 {
            font-size: 16px;
            margin-bottom: 7px;
        }
        p {
            font-size: 14px;
        }
        .posu-first-img {
            margin-right: 40px;
        }
    }
    .get-bitvpn {
        text-align: center;
        padding-top: 6px;
        h2 {
            font-size: 24px;
            color: rgba(38,38,38,1);
            margin-bottom: 22px;
        }
        .map-bg {
            background-image: url('../../assets/images/home_map_m.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            padding: 0 16px;
        }
    }
}
@media (min-width: 576px) {
    .home {
        background-image: url('../../assets/images/home_header_bg.png');
        .bg-container {
            background-image: url('../../assets/images/home_computer2.png');
            background-size: 2000px;
        }
        .get-bitvpn {
            h2 {
                font-size: 56px;
                margin-bottom: 40px;
            }
            .map-bg {
                background-image: url('../../assets/images/home_map.png');
            }
        }
        .posu {
            margin-top: 58px;
            h4 {
                font-size: 24px;
            }
            p {
                font-size: 17px;
            }
            .posu-item {
                width: 50%;
                padding-right: 100px;
                margin-bottom: 70px;
            }
            img {
                margin-right: 37px;
            }
            .posu-first-img {
                margin-right: 50px;
            }
        }
        .bit-video {
            padding: 40px;
            display: flex;
            flex-direction: row-reverse;
            background-image: url('../../assets/images/home_video_bg.png');
            background-size: 233px 231px;
            .youtube-container-img {
                margin-right: 56px;
                flex-shrink: 0;
                width: 540px;
                max-width: 540px;
            }
            h3 {
                font-size: 60px;
                margin-bottom: 8px;
            }
            .h3-mobile {
                display: none;
            }
            .h3-pc {
                display: block;
            }
            p {
                font-size:36px;
                display: block;
            }
        }
        .vpn-with-aa {
            padding-top: 170px;
            padding-bottom: 310px;
            h2 {
                font-size: 56px;
                margin-bottom: 8px;
            }
            p {
                font-size: 24px;
                text-align: left;
            }
        }
        .why-choose-bitvpn {
            margin-top: 103px;
            margin-bottom: 132px;
            h2 {
                font-size:42px;
            }
            ul {
                margin-top: 46px;
            }
            h4 {
                font-size: 24px;
                margin: 13px 0 3px;
            }
            p {
                font-size: 17px;
            }
            li {
                display: block;
                text-align: center;
            }
            img {
                margin-right: 0;
            }
        }
        .h-top {
            h1 {
                font-size: 42px;
                line-height: 48px;
            }
            p {
                font-size: 16px;
                margin: 2px 0 32px;
            }
            .get-now-wrap {
                padding: 0;
                button {
                    width: 260px;
                }
            }
            img {
                display: inline-block;
                width: 300px;
                bottom: -0px;
                right: -40px;
                position: absolute;
            }
            .left-content {
                width: 300px;
            }
            button {
                padding: 14px 40px;
            }
        }
        .banner-img {
            display: none;
        }
    }
}
@media (min-width: 768px) {
    .home {
        .h-top {
            img {
                width: 510px;
                bottom: -30px;
            }
        }
    }
}
@media (min-width: 992px) {
    .home {
        .h-top {
            h1 {
                font-size: 76px;
                line-height: 83px;
            }
            p {
                font-size: 24px;
                margin: 16px 0 36px;
            }
            .get-now-wrap {
                padding: 0;
            }
            img {
                right: -20px;
            }
            .left-content {
                width: 500px;
            }
            button {
                padding: 24px 40px;
            }
        }
    }
}
@media (min-width: 1200px) {
    .home {
        .h-top {
            img {
                width: 700px;
            }
        }
    }
}
@media (min-width: 1500px) {
    .home {
        .h-top {
            img {
                right: -120px;
                width: 820px;
            }
        }
    }
}

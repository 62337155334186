
.bit-btc {
    .BTC {
        border-bottom: 1px solid rgba(222,222,222,1);
        padding-bottom: 10px;
        p {
            font-size: 16px;
            margin-top: 8px;
            display: flex;
            img {
                width: 12px;
                height: 12px;
                display: inline-block;
                line-height: 12px;
                font-size: 12px;
                align-self: flex-start;
                margin-top: 4px;
                margin-right: 8px;
                flex-shrink: 0;
            }
        }
    }
}


.bit-braintree {
    .braintree {
    }
    .input-item-wrap {
        padding: 9px 16px;
        background: rgba(250,250,250,1);
        border: 1px solid rgba(211,211,211,1);
        width: 100%;
        color: rgba(38,38,38,1);
        font-size: 18px;
        transition: all .3s;
        outline: none;
        height: 40px;
        &.braintree-hosted-fields-focused {
            background: rgba(255,255,255,1);
            border: 1px solid rgba(102,102,102,1);
        }
    }
    .invalid-feedback{
        color : rgba(255,255,255,1);
        height :22px;
        user-select: none;
    }
    .check-error{
        border: 1px solid #CA2A2A;
    }
    .tip-error{
        color : #CA2A2A;
    }
    .b-line {
        border-top: 1px solid rgba(222,222,222,1);
        padding-top: 0px;
        margin-top: 8px;
    }
    .b-btn-wrap {
        margin: 24px 0px;
    }
    .input-item {
        margin-bottom: 10px;
    }
}
@media (min-width: 576px) {
    .bit-braintree {
        .b-line {
            padding-top: 10px;
            margin-top: 0px;
        }
        .b-btn-wrap {
            margin: 32px 0px 24px;
        }
    }
}
@media (max-width: 576px) {
    .bit-braintree {
        .input-item {
            margin-bottom: 0px;
        }
    }
}

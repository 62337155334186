*,
*::before,
*::after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, p, ul {
    margin: 0;
    padding: 0;
}
li {
    list-style: none;
}
button {
    border: 0;
    outline: none;
}
a {
    text-decoration: none;
}
a[href^="mailto:"] {
    color: rgba(12,84,241,1);
}
#app {
    overflow-x: hidden;
}
body {
    font-family: PingFangSC-Regular, Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.375
}
::placeholder {
    color: rgba(222,222,222,1);
}
.bitm {
    font-weight: 500;
    line-height: 1.4;
    font-family: PingFangSC-Medium, Helvetica, Arial, sans-serif;
}
.container {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-left: -16px;
}
.col-12, .col-sm-4, .col-sm-6, .col-sm-12, .col-md-2, .col-md-4, .col-md-6, .col-md-10, .col-md-12, .col-lg-2, .col-lg-4, .col-lg-8, .col-lg-10, .col-xl-2, .col-xl-3, .col-xl-8, .col-xl-9, .col-xl-10 {
    position: relative;
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
}
.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}
.btn-wrap {
    padding: 0 20px;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
    .col-sm-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .btn-wrap {
        padding: 0;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
    .col-md-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
    .col-lg-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-lg-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-lg-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-lg-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
    .col-xl-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xl-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xl-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
}